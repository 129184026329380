import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>LUCAS ALVES OLIVEIRA DESENVOLVIMENTO DE SOFTWARE</h1>
        <p>CNPJ: 34.566.465/0001-26</p>
      </header>
    </div>
  );
}

export default App;
